services = angular.module("clientApp.services", [])


services.factory 'SurveysRef', [
    '$firebaseArray'
    '$firebaseObject'
    ($firebaseArray,$firebaseObject) ->

        getSurveyService: (survey) ->
            ref = firebase.database().ref('Surveys/'+ survey + '/')
            obj = $firebaseObject(ref)
            return {ref:ref, obj:obj}

        getSurveyMailingRespondentsService: (id, mailing) ->
            ref = firebase.database().ref('SurveyRespondents/'+ id + '/dynamic/' + mailing)
            obj = $firebaseArray(ref)
            return {ref:ref, obj:obj}

        getSurveyRespondentsStructureService: (id) ->
            ref = firebase.database().ref('SurveyRespondentsStructures/'+ id + '/')
            obj = $firebaseArray(ref.orderByChild('position'))
            return {ref:ref, obj:obj}
]


services.factory 'MailingsRef', [
    '$firebaseArray'
    '$firebaseObject'
    ($firebaseArray,$firebaseObject) ->

        getSurveyMailingsService: (survey) ->
            ref  = firebase.database().ref('SurveyMailings/'+ survey + '/')
            obj = $firebaseArray(ref.orderByChild('order'))
            return {ref:ref, obj:obj}

        getSurveyMailingsImportStatusService: (survey, mailing) ->
            ref  = firebase.database().ref('SurveyMailingsStatus/'+ survey + '/' + mailing + '/import')
            obj = $firebaseObject(ref)
            return {ref:ref, obj:obj}

        getSurveyMailingRespondentsService: (id, mailing) ->
            ref = firebase.database().ref('SurveyRespondents/'+ id + '/dynamic/' + mailing)
            obj = $firebaseArray(ref)
            return {ref:ref, obj:obj}

        getSurveyMailingImportLogsService: (id, mailing) ->
            ref = firebase.database().ref('SurveyMailingsLogs/'+ id + '/' + mailing )
            obj = $firebaseArray(ref.orderByChild('order'))
            return {ref:ref, obj:obj}
]


services.factory 'ResponsesRef', [
    '$firebaseArray'
    '$firebaseObject'
    '$cookieStore'
    ($firebaseArray,$firebaseObject,$cookieStore) ->

        getRespondentsResponsesService: (id) ->
            ref = firebase.database().ref('SurveyResponses/'+ id)
            obj = $firebaseArray(ref)
            return {ref:ref, obj:obj}
]


services.factory 'UserRef', [
    '$firebaseArray'
    '$firebaseObject'
    ($firebaseArray,$firebaseObject) ->

        getUserService: (user) ->
            ref = firebase.database().ref('Users/'+ user + '/')
            obj = $firebaseObject(ref)
            return {ref:ref, obj:obj}

        getUsersService: ->
            ref = firebase.database().ref('Users/')
            obj = $firebaseArray(ref)
            return {ref:ref, obj:obj}

        getUserNotificationsService: (user) ->
            ref = firebase.database().ref('Notifications/'+ user + '/')
            obj = $firebaseArray(ref.orderByChild('systime'))
            return {ref:ref, obj:obj}
]


services.factory 'JobsRef', [
    '$firebaseArray'
    '$firebaseObject'
    '$cookieStore'
    ($firebaseArray,$firebaseObject,$cookieStore) ->

        getJobsService: ->
            ref  = firebase.database().ref('Jobs/')
            obj  = $firebaseArray(ref)
            return {ref:ref, obj:obj}
]


services.factory 'ClientDocumentFileRef', [
    '$firebaseArray'
    '$firebaseObject'
    ($firebaseArray,$firebaseObject) ->

        getClientDocumentsService: (client, survey) ->
            ref = firebase.database().ref('ClientDocuments/'+ client + '/' + survey + '/uploads/')
            obj = $firebaseArray(ref.orderByChild('order'))
            return {ref:ref, obj:obj}
]


services.factory 'ClientDocumentFile', [
    '$http'
    '$q'
    ($http,$q) ->

        URL = 'https://' + publicDomain + '/api/clientfile/'

        downloadFile: (survey, user, client, document, password) ->
            p = $q.defer()
            $http.get(URL, {params:{s : survey, u:user, c:client, f:document, p : password }, responseType: 'arraybuffer'}).then(
                (databack) ->
                    p.resolve(databack)
                (error) ->
                    p.reject(error)
            )
            p.promise



        delete: (survey, user, client, document) ->
            p = $q.defer()

            $http({url: URL,method: "DELETE",data: object_b64_safe({survey : survey, user:user, client:client, document:document}), headers: {"Content-Type": `undefined`}}).then(
                (databack) ->
                    if databack.data[0]
                        p.resolve(databack.data[1])
                    else
                        p.reject(databack.data[1])

                (error) ->
                    p.reject(error)
            )
            p.promise
]
