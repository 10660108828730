SYS_LANGAUGES  = [
            {code:'ar', name:'Arabic'},
            {code:'br', name:'Brazilian'},
            {code:'ca', name:'Catalan'},
            {code:'cn', name:'Chinese'},
            {code:'cs', name:'Czech'},
            {code:'da', name:'Danish'},
            {code:'nl', name:'Dutch'},
            {code:'en', name:'English'},
            {code:'fi', name:'Finnish'},
            {code:'fr', name:'French'},
            {code:'fr1',name:'French 1'},
            {code:'de', name:'German'},
            {code:'de1',name:'German 1'},
            {code:'de2',name:'German 2'},
            {code:'hu', name:'Hungarian'},
            {code:'it', name:'Italian'},
            {code:'jp', name:'Japanese'},
            {code:'kr', name:'Korean'},
            {code:'lv', name:'Latvian'},
            {code:'ms', name:'Malay'},
            {code:'no', name:'Norwegian'},
            {code:'pl', name:'Polish'},
            {code:'pt', name:'Portuguese'},
            {code:'ro', name:'Romanian'},
            {code:'ru', name:'Russian'},
            {code:'sk', name:'Slovak'},
            {code:'es', name:'Spanish'},
            {code:'sv', name:'Swedish'},
            {code:'tw', name:'Taiwanese'},
            {code:'th', name:'Thai'},
            {code:'tr', name:'Turkish'}
]