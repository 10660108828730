FUTURE_TIME = 2000000000000

# Controllers
formatAuthErrorMsg = (error) ->
    switch error.code
        when "auth/argument-error" then "Enter a valid e-mail and password."
        when "auth/user-not-found" then "The password is invalid"
        when "auth/wrong-password" then "The password is invalid"
        when "auth/network-request-failed" then error.message
        else error.message

########################################################################################
## guid
########################################################################################
guid = ->
    s4 = ->
        Math.floor((1 + Math.random()) * 0x10000).toString(16).substring 1

    #return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
    return s4() + s4() + s4() + s4() + s4()  + s4() + s4() + s4()


##############################################################################
## toJSONLocal
##############################################################################
toJSONLocal = (date) ->
  local = new Date(date)
  local.setMinutes date.getMinutes() - date.getTimezoneOffset()
  slocal = local.toJSON()
  return slocal.slice(0, 10)  + ' ' + slocal.slice(11, 16)



##############################################################################
## b64_encode
##############################################################################
b64_encode_string = (s) ->
    if not exist(s)
        return s

    if not exist(s.indexOf)
        return s

    if s.indexOf(b64safe) == 0
        return s

    s = encodeURIComponent(s)
    s = base64_encode(s)
    s = s.replace /\=/g, "-"
    return s


##############################################################################
## controllers
##############################################################################
controllers = angular.module("clientApp.controllers", [])


##############################################################################
## LANGUAGES
##############################################################################
LANGUAGES  = [
                {code:'ar', name:'Arabic'},
                {code:'br', name:'Brazilian'},
                {code:'ca', name:'Catalan'},
                {code:'cn', name:'Chinese'},
                {code:'cs', name:'Czech'},
                {code:'da', name:'Danish'},
                {code:'nl', name:'Dutch'},
                {code:'en', name:'English'},
                {code:'fi', name:'Finnish'},
                {code:'fr', name:'French'},
                {code:'fr1',name:'French 1'},
                {code:'de', name:'German'},
                {code:'de1',name:'German 1'},
                {code:'de2',name:'German 2'},
                {code:'hu', name:'Hungarian'},
                {code:'it', name:'Italian'},
                {code:'jp', name:'Japanese'},
                {code:'kr', name:'Korean'},
                {code:'lv', name:'Latvian'},
                {code:'ms', name:'Malay'},
                {code:'no', name:'Norwegian'},
                {code:'pl', name:'Polish'},
                {code:'pt', name:'Portuguese'},
                {code:'ro', name:'Romanian'},
                {code:'ru', name:'Russian'},
                {code:'sk', name:'Slovak'},
                {code:'es', name:'Spanish'},
                {code:'sv', name:'Swedish'},
                {code:'tw', name:'Taiwanese'},
                {code:'th', name:'Thai'},
                {code:'tr', name:'Turkish'}
        ]

##############################################################################
## LoginController
##############################################################################
controllers.controller 'LoginController', [
    '$scope'
    '$location'
    '$cookieStore'
    'Auth'
    'UserRef'
    ($scope, $location, $cookieStore, Auth, UserRef) ->
        $scope.userService         = null
        $scope.firebaseUser        = null
        $scope.user                = {}
        $scope.errorMsg            = null

        Auth.$onAuthStateChanged (firebaseUser) ->
            $scope.firebaseUser = firebaseUser
            return

        $scope.doLogin = ->
            $scope.errorMsg = null
            try
                Auth.$signInWithEmailAndPassword($scope.user.email, $scope.user.password).then(
                    (firebaseUser) ->
                        $cookieStore.put 'password', $scope.user.password

                        $scope.user = {}
                        $scope.userService = UserRef.getUserService(firebaseUser.user.uid)

                        $scope.userService.obj.$loaded().then ->
                            if $scope.userService.obj.info.role == 'integron' or $scope.userService.obj.info.role == 'sales' or $scope.userService.obj.info.role == 'admin'
                                $location.path '/adminlogin'
                            if $scope.userService.obj.info.role == 'client'
                                if $scope.userService.obj.hasOwnProperty('settings')

                                    surveys = []

                                    for key,setting of $scope.userService.obj.settings
                                        surveys.push key

                                    if surveys.length == 1
                                        $cookieStore.put 'survey', surveys[0]
                                        $location.path '/overview'
                                    else
                                        $location.path '/surveys'
                                else
                                    $location.path '/notauthorized/'

                        return
                    ).catch (error) ->
                        console.error 'Authentication failed:', error
                        $scope.errorMsg = formatAuthErrorMsg error
                        return
            catch error
                console.error 'Could not start authentication:', error
                $scope.errorMsg = formatAuthErrorMsg error
                return

        $scope.doSignOut = ->
            Auth.$signOut()
]




##############################################################################
## AdminLoginController
##############################################################################
controllers.controller 'AdminLoginController', [
    '$scope'
    '$location'
    '$cookieStore'
    'Auth'
    'currentAuth'
    'UserRef'
    ($scope, $location, $cookieStore, Auth, currentAuth, UserRef) ->
        $scope.userService         = UserRef.getUserService(currentAuth.uid)
        $scope.firebaseUser        = null
        $scope.user                = {'email' : integronClientAdminEmail}
        $scope.errorMsg            = null

        $scope.userService.obj.$loaded().then ->
            if $scope.userService.obj.info.role not in ['admin','integron','sales']
                $location.path '/notauthorized/'

        Auth.$onAuthStateChanged (firebaseUser) ->
            $scope.firebaseUser = firebaseUser
            return

        $scope.doLogin = ->
            $scope.errorMsg = null
            try
                Auth.$signInWithEmailAndPassword($scope.user.email, $scope.user.password).then(
                    (firebaseUser) ->
                        $cookieStore.put 'password', $scope.user.password

                        $scope.user = {}
                        $scope.userService = UserRef.getUserService(firebaseUser.user.uid)

                        $scope.userService.obj.$loaded().then ->
                            if $scope.userService.obj.info.role != 'clientadmin'
                                $location.path '/notauthorized'
                            if $scope.userService.obj.info.role == 'clientadmin'
                                $location.path '/clients'

                        return
                    ).catch (error) ->
                        console.error 'Authentication failed:', error
                        $scope.errorMsg = formatAuthErrorMsg error
                        return
            catch error
                console.error 'Could not start authentication:', error
                $scope.errorMsg = formatAuthErrorMsg error
                return
]


##############################################################################
## ClientsController
##############################################################################
controllers.controller 'ClientsController', [
    '$scope'
    '$location'
    '$routeParams'
    '$cookieStore'
    'currentAuth'
    'UserRef'
    'Auth'
    ($scope, $location, $routeParams, $cookieStore, currentAuth, UserRef, Auth) ->
        $scope.userService        = UserRef.getUserService(currentAuth.uid)
        $scope.selected           = null
        $scope.clients            = []

        $scope.userService.obj.$loaded().then ->

            if $scope.userService.obj.info.role == 'clientadmin'
                $scope.clientsService = UserRef.getUsersService()

                $scope.clientsService.obj.$loaded().then ->

                    for client in $scope.clientsService.obj
                        if client.hasOwnProperty('info')
                            if client.info.role == 'client'
                                $scope.clients.push(client.info)
            else
                $location.path '/notauthorized/'


        $scope.doChooseClient = ->
            if $scope.selected
                $cookieStore.put 'client', $scope.selected

                $scope.clientService        = UserRef.getUserService($scope.selected)

                $scope.clientService.obj.$loaded().then ->
                    if $scope.clientService.obj.hasOwnProperty('settings')

                        surveys = []

                        for key,setting of $scope.clientService.obj.settings
                            surveys.push key

                        if surveys.length == 1
                            $cookieStore.put 'survey', surveys[0]
                            $location.path '/overview'
                        else
                            $location.path '/surveys'
]



##############################################################################
## SurveysController
##############################################################################
controllers.controller 'SurveysController', [
    '$scope'
    '$location'
    '$routeParams'
    '$cookieStore'
    'currentAuth'
    'SurveysRef'
    'UserRef'
    'Auth'
    ($scope, $location, $routeParams, $cookieStore, currentAuth, SurveysRef, UserRef, Auth) ->
        $scope.userService        = UserRef.getUserService(currentAuth.uid)
        $scope.surveys            = []
        $scope.selected           = null
        $scope.initialized        = false
        $scope.surveysToInitialize= 0

        $scope.InitializeSurveysForClient = (client) ->
            surveys = []
            for key,setting of client.settings
                surveys.push key
            $scope.surveysToInitialize = surveys.length
            $scope.initialized         = true
            for survey in surveys
                $scope.GetSurveyName(survey)

        $scope.userService.obj.$loaded().then ->
            authorizedUser = false

            console.log $scope.userService.obj.info

            if $scope.userService.obj.info.role in ['client','clientadmin']
                authorizedUser = true

            if $scope.userService.obj.info.role == 'client'
                if $scope.userService.obj.hasOwnProperty('settings')
                    $scope.InitializeSurveysForClient($scope.userService.obj)

            if $scope.userService.obj.info.role == 'clientadmin'
                $scope.clientService        = UserRef.getUserService($cookieStore.get('client'))

                $scope.clientService.obj.$loaded().then ->

                    if $scope.clientService.obj.hasOwnProperty('settings')
                        $scope.InitializeSurveysForClient($scope.clientService.obj)

            if not authorizedUser
                $location.path '/notauthorized/'


        $scope.GetSurveyName = (surveyId) ->
            surveyService  = SurveysRef.getSurveyService(surveyId)

            surveyService.obj.$loaded().then ->
                if surveyService.obj.hasOwnProperty('info')
                    $scope.surveys.push {id : surveyId, name : surveyService.obj.info.name}

                $scope.surveysToInitialize = $scope.surveysToInitialize - 1

        $scope.doChooseSurvey = ->
            if $scope.selected
                $cookieStore.put 'survey', $scope.selected
                $location.path '/overview'

        $scope.$watch "surveysToInitialize", ((newVal, oldVal) ->

            if newVal == 0 and $scope.initialized
                $scope.surveys.sort (a, b) ->
                    if a['name'] < b['name']
                        return -1
                    if a['name'] > b['name']
                        return 1
                    return 0

        ), true
]



##############################################################################
## OverviewController
##############################################################################
controllers.controller 'OverviewController', [
    '$scope'
    '$location'
    '$cookieStore'
    'currentAuth'
    'UserRef'
    'Auth'
    ($scope, $location, $cookieStore, currentAuth, UserRef, Auth) ->
        $scope.userService        = UserRef.getUserService(currentAuth.uid)
        $scope.survey             = $cookieStore.get('survey')

        $scope.userService.obj.$loaded().then ->
            authorizedUser = false

            if $scope.userService.obj.info.role in ['client','clientadmin']
                authorizedUser = true

            if not authorizedUser
                $location.path '/notauthorized/'
]



##############################################################################
## MailingsListController
##############################################################################
controllers.controller 'MailingsListController', [
    '$scope'
    '$location'
    '$routeParams'
    '$cookieStore'
    'currentAuth'
    'UserRef'
    'MailingsRef'
    'JobsRef'
    'Auth'
    ($scope, $location, $routeParams, $cookieStore, currentAuth, UserRef, MailingsRef, JobsRef, Auth) ->
        $scope.survey             = $cookieStore.get('survey')
        $scope.userService        = UserRef.getUserService(currentAuth.uid)
        $scope.clientId           = null
        $scope.jobsService        = JobsRef.getJobsService()
        $scope.settings           = {survey : $scope.survey, mailing : null, dashboard : null}
        $scope.userMailings       = []
        $scope.templateMailing    = null
        $scope.initialized        = false

        $scope.userService.obj.$loaded().then ->

            if $scope.userService.obj.info.role == 'client'
                $scope.clientId = $scope.userService.obj.info.id
                if $scope.userService.obj.hasOwnProperty('settings')
                    surveys = $scope.GetSurveyListFromSettings($scope.userService.obj)
                    if $scope.survey in surveys
                        $scope.inititalizeMailings()
                    else
                        $location.path '/notauthorized/'

            if $scope.userService.obj.info.role == 'clientadmin'
                $scope.clientId = $cookieStore.get('client')
                $scope.clientService        = UserRef.getUserService($cookieStore.get('client'))

                $scope.clientService.obj.$loaded().then ->
                    surveys = $scope.GetSurveyListFromSettings($scope.clientService.obj)
                    if $scope.survey in surveys
                        $scope.inititalizeMailings()
                    else
                        $location.path '/notauthorized/'


        $scope.GetSurveyListFromSettings = (surveyServiceObj) ->
            surveys = []
            if surveyServiceObj.hasOwnProperty('settings')
                for key,setting of surveyServiceObj.settings
                    if key == $scope.survey
                        if setting.blockMailing
                            $scope.settings.mailing = setting.mailing
                        if setting.blockDashboard
                            $scope.settings.dashboard = setting.dashboard
                    surveys.push key
            return surveys

        $scope.inititalizeMailings = ->

            $scope.surveyMailingsService = MailingsRef.getSurveyMailingsService($scope.settings.survey)

            $scope.surveyMailingsService.obj.$loaded().then ->
                for mailing in $scope.surveyMailingsService.obj
                    console.log mailing
                    if mailing['creator'] == $scope.clientId
                        $scope.userMailings.push(mailing)
                    if mailing['id'] == $scope.settings.mailing
                        $scope.templateMailing = mailing
                $scope.initialized = true
                console.log $scope.clientId
                console.log $scope.userMailings

        $scope.canMailingBeAdded = ->
            canBeAdded = true
            for mailing in $scope.surveyMailingsService.obj
                if mailing.creator == $scope.clientId
                    if mailing.hasOwnProperty('sent')
                        if mailing['sent'] == false
                            canBeAdded = false
                    else
                        canBeAdded = false
            return canBeAdded

        $scope.addMailing = ->

            if not $scope.canMailingBeAdded()
                alert('You can create a new mailing only after all the previous ones were successfully sent.')
            else
                date    = new Date()
                #text    = 'Mailing ' + toJSONLocal(date)
                newId   = guid()

                newMailing =
                    id            : newId
                    text          : 'New mailing'
                    composition   : $scope.templateMailing.composition
                    type          : 'MAILING'
                    scheduler     : $scope.templateMailing.scheduler
                    schedulertime : $scope.templateMailing.schedulertime
                    creator       : $scope.clientId
                    usecustom     : $scope.templateMailing.usecustom
                    progress      : 0
                    reminder      : $scope.templateMailing.reminder
                    interval      : $scope.templateMailing.interval
                    invitations   : $scope.templateMailing.invitations
                    status        : 'CREATED'
                    recipients    : 0
                    systime       : Date.now()
                    order         : FUTURE_TIME - Date.now()
                    sent          : false

                if $scope.templateMailing.hasOwnProperty('customcolor')
                    newMailing['customcolor'] = $scope.templateMailing['customcolor']

                $scope.surveyMailingsService.obj.$add newMailing

                if $scope.templateMailing.usecustom
                    $scope.jobsService.obj.$add {'type':'copy_mailing_template', 'state' : 'WAITING_FOR_PROCESS', 'data':{'survey':$scope.settings.survey, 'user':$scope.clientId, 'source':  $scope.templateMailing.id, 'target':newId}}

                $location.path '/mailing/' + newId


        $scope.GetDate = (date) ->
            if date
                return toJSONLocal(new Date(date))

        return

    ]


##############################################################################
## MailingItemController
##############################################################################
controllers.controller 'MailingItemController', [
    '$scope'
    '$location'
    '$routeParams'
    '$cookieStore'
    'currentAuth'
    'UserRef'
    'JobsRef'
    'SurveysRef'
    'MailingsRef'
    'ResponsesRef'
    'Auth'
    ($scope, $location, $routeParams, $cookieStore, currentAuth, UserRef, JobsRef, SurveysRef, MailingsRef, ResponsesRef, Auth) ->
        $scope.survey                    = $cookieStore.get('survey')
        $scope.userService               = UserRef.getUserService(currentAuth.uid)
        $scope.jobsService               = JobsRef.getJobsService()
        $scope.settings                  = {survey : $scope.survey, mailing : $routeParams.mailingId, language : {code:'nl', name:'Dutch'}, chapter : 'respondents', respondent: null}
        $scope.clientId                  = null
        $scope.selectedMailing           = null
        $scope.selectedLog               = null
        $scope.exportemail               = null
        $scope.mailingsImportStatus      = null
        $scope.loadingRespondents        = false
        $scope.surveyResponsesTimes      = {}
        $scope.surveyRespondents         = []
        $scope.selectedMailingLanguages  = []
        $scope.fieldLinks                = ['RESPONDENT_LOGIN_LINK']
        $scope.participationRate         = 0
        $scope.editorOptions             = {language: 'en', width : '100%', height : '500px'}

        $scope.surveyRespondentsStructureService = SurveysRef.getSurveyRespondentsStructureService($scope.settings.survey)
        $scope.mailingRespondentsService         = SurveysRef.getSurveyMailingRespondentsService($scope.settings.survey, $scope.settings.mailing)
        $scope.mailingsImportStatusService       = MailingsRef.getSurveyMailingsImportStatusService($scope.settings.survey,$scope.settings.mailing)
        $scope.mailingImportLogsService          = MailingsRef.getSurveyMailingImportLogsService($scope.settings.survey,$scope.settings.mailing)


        schema = model:
          id:     'id'
          fields: {}


        $scope.respondentsOptionsDS = new kendo.data.DataSource({
                data: new kendo.data.ObservableArray([])
                schema: schema
            })


        $scope.respondentsOptions =
            selectable: true
            resizable:  true
            sortable:   true
            pageable:
                refresh: true
                pageSizes: true
            dataSource:  new kendo.data.DataSource({data: new kendo.data.ObservableArray([])})


        $scope.logOptionsDS = new kendo.data.DataSource({
                data: new kendo.data.ObservableArray([])
                schema: schema
            })


        $scope.logOptions =
            selectable: true
            resizable:  true
            sortable:   true
            pageable:
                refresh: true
                pageSizes: true
            dataSource:  new kendo.data.DataSource({data: new kendo.data.ObservableArray([])})


        $scope.GetSurveyListFromSettings = (surveyServiceObj) ->
            surveys = []
            if surveyServiceObj.hasOwnProperty('settings')
                for key,setting of surveyServiceObj.settings
                    surveys.push key
            return surveys


        $scope.userService.obj.$loaded().then ->

            if $scope.userService.obj.info.role == 'client'
                $scope.clientId = $scope.userService.obj.info.id
                if $scope.userService.obj.hasOwnProperty('settings')
                    surveys = $scope.GetSurveyListFromSettings($scope.userService.obj)
                    if $scope.survey in surveys
                        $scope.inititalizeMailing()
                    else
                        $location.path '/notauthorized/'

            if $scope.userService.obj.info.role == 'clientadmin'
                $scope.clientId = $cookieStore.get('client')
                $scope.clientService        = UserRef.getUserService($cookieStore.get('client'))

                $scope.clientService.obj.$loaded().then ->
                    surveys = $scope.GetSurveyListFromSettings($scope.clientService.obj)
                    if $scope.survey in surveys
                        $scope.inititalizeMailing()
                    else
                        $location.path '/notauthorized/'


        $scope.mailingImportLogsService.obj.$loaded().then ->
            return

        $scope.mailingsImportStatusService.obj.$bindTo($scope, 'mailingsImportStatus').then ->
            return


        $scope.surveyRespondentsStructureService.obj.$loaded().then ->
            for column in $scope.surveyRespondentsStructureService.obj
                if column.column not in ['id','loginname','type','password','language']
                    $scope.fieldLinks.push column.column
            $scope.InitializeRespondents()
            return


        $scope.GetDate = (date) ->
            return toJSONLocal(new Date(date))


        $scope.InitializeRespondents = ->
            $scope.respondentsOptions.columns = []

            schema.model.fields['invitation'] = { type: "string", editable: false}
            $scope.respondentsOptions.columns.push {field: 'invitation'}

            schema.model.fields['firstresponse'] = { type: "string", editable: false}
            $scope.respondentsOptions.columns.push {field: 'firstresponse'}

            for column in $scope.surveyRespondentsStructureService.obj

                if column.column == 'id'
                    schema.model.fields['id'] = { editable: false, nullable: false }
                    $scope.respondentsOptions.columns.push {field: 'id', hidden:true}
                else
                    if column.column not in  ['loginname','type','password','email']
                        $scope.respondentsOptions.columns.push {field: column.column}
                        schema.model.fields[column.column] = { type: "string", editable: true}

            $scope.respondentsOptionsDS = new kendo.data.DataSource({
                            data: new kendo.data.ObservableArray([])
                            schema: schema
            })

            grid = $("#respondentslist").data("kendoGrid")
            if grid
                grid.setDataSource($scope.respondentsOptionsDS)
                grid.setOptions($scope.respondentsOptions)


        $scope.DisplayRespondents = ->
            $scope.loadingRespondents = true

            $scope.surveyResponsesService         = ResponsesRef.getRespondentsResponsesService($scope.settings.survey)

            $scope.surveyResponsesService.obj.$loaded().then ->
                for response in $scope.surveyResponsesService.obj
                    invitationTime      = ''
                    firstresponseTime   = ''
                    if response.hasOwnProperty('invitation')
                        invitationTime = response['invitation']
                    if response.hasOwnProperty('firstlogintime')
                        firstresponseTime = response['firstlogintime']
                    $scope.surveyResponsesTimes[response.$id] = {invitation:invitationTime , firstresponse:firstresponseTime}

                $scope.surveyRespondents  = []

                firestore.collection('SurveyRespondents_' + $scope.settings.survey).get().then (querySnapshot) ->
                    validrespondents = []
                    for subsetRespondents in $scope.mailingRespondentsService.obj
                        for respondentId, lastUpdateDate of subsetRespondents
                            validrespondents.push respondentId

                    if querySnapshot

                        mailingPatricipation = 0

                        querySnapshot.forEach (doc) ->
                            respondentData   = doc.data()

                            if respondentData['id'] in validrespondents
                                respondentData = $scope.GetRespondentData(respondentData)
                                if respondentData['firstresponseraw'] > 0
                                    mailingPatricipation = mailingPatricipation + 1
                                $scope.surveyRespondents.push respondentData

                    if $scope.surveyRespondents.length > 0
                        $scope.participationRate = Math.ceil((mailingPatricipation*100) / $scope.surveyRespondents.length)


                    $scope.respondentsOptionsDS = new kendo.data.DataSource({
                                    data: new kendo.data.ObservableArray($scope.surveyRespondents)
                                    schema: schema
                                    pageSize: 500
                    })

                    $scope.respondentsOptions.dataSource.data $scope.respondentsOptionsDS.data()

                    $scope.loadingRespondents = false

                    $scope.$digest()
                    $scope.$apply()


        $scope.GetRespondentData = (data) ->
            respondentData = {}
            for key,value of data
                respondentData[key] = value
            respondentData['invitation']       = ''
            respondentData['firstresponse']    = ''
            respondentData['firstresponseraw'] = 0

            if $scope.surveyResponsesTimes.hasOwnProperty(respondentData['id'])
                if $scope.surveyResponsesTimes[respondentData['id']]['invitation']
                    invitation    = new Date(Number($scope.surveyResponsesTimes[respondentData['id']]['invitation']))
                    respondentData['invitation']    = invitation.toLocaleString()
                if $scope.surveyResponsesTimes[respondentData['id']]['firstresponse']
                    firstresponse = new Date(Number($scope.surveyResponsesTimes[respondentData['id']]['firstresponse']))
                    respondentData['firstresponse']    = firstresponse.toLocaleString()
                    respondentData['firstresponseraw'] = $scope.surveyResponsesTimes[respondentData['id']]['firstresponse']
            return respondentData



        $scope.inititalizeMailing = ->

            $scope.surveyMailingsService = MailingsRef.getSurveyMailingsService($scope.settings.survey)

            $scope.surveyMailingsService.obj.$loaded().then ->
                for mailing in $scope.surveyMailingsService.obj
                    if mailing['id'] == $scope.settings.mailing
                        $scope.selectedMailing = mailing

                        selectedChapter = 'respondents'
                        if $scope.selectedMailing.status == 'IMPORT_START'
                            if $scope.mailingsImportStatus
                                if $scope.mailingsImportStatus.hasOwnProperty('inprogress')
                                    if $scope.mailingsImportStatus['inprogress']
                                        selectedChapter = 'status'

                        if $scope.selectedMailing.status == 'IMPORT_END'
                            selectedChapter = 'respondents'

                        if $scope.selectedMailing.status == 'MAILING_START'
                            selectedChapter = 'sending'

                        if $scope.selectedMailing.status == 'MAILING_END'
                            selectedChapter = 'logs'

                        $scope.SetChapter(selectedChapter)

                        $scope.selectedMailingLanguages  = []

                        avalaibleMailingLanguages = []

                        for key,value of $scope.selectedMailing.composition
                            avalaibleMailingLanguages.push key

                        for language in LANGUAGES
                            if language.code in avalaibleMailingLanguages
                                $scope.selectedMailingLanguages.push language

                        $scope.settings.language = $scope.selectedMailingLanguages[0]



        $scope.RealoadRespondentsList = ->
            $scope.SetChapter('respondents')
            $scope.DisplayRespondents()


        $scope.SetChapter = (chapter) ->
            $scope.settings.chapter = chapter
            $(this).trigger('shown')
            $.each $('.tab-pane'), (key, value) ->
                $(value).removeClass 'active'

            visited = true
            for chapterItem in ['respondents','mailing','send','logs']
                id = '#selector_' + chapterItem
                $(id).removeClass("visited")
                if chapter == chapterItem
                    visited = false
                if visited
                    $(id).addClass("visited")

            id = '#' + chapter
            $(id).addClass("active")

            if chapter == 'mailing'
                $scope.settings.language = $scope.selectedMailingLanguages[0]


        $scope.SetSubchapter = (chapter) ->
            $(this).trigger('shown')
            $.each $('.tab-pane'), (key, value) ->
                $(value).removeClass 'active'
            id = '#' + chapter
            $(id).addClass("active")


        $scope.doSaveSelectedMailing = ->
            $scope.surveyMailingsService.obj.$save($scope.selectedMailing)


        $scope.doSaveSelectedMailingForLanguage = ->
            $scope.doSaveSelectedMailing()

            pos = $scope.selectedMailingLanguages.indexOf($scope.settings.language)

            if pos > -1
                if pos < $scope.selectedMailingLanguages.length - 1
                    $scope.settings.language = $scope.selectedMailingLanguages[pos + 1]
                else
                    $scope.SetChapter('send')


        $scope.doAddMailingColumnToText = (fieldLink) ->
            $scope.selectedMailing['composition'][ $scope.settings.language.code]['mailhtml'] += ' ##' + fieldLink + '##'


        $scope.doAddMailingColumnToReminderText = (fieldLink) ->
            $scope.selectedMailing['composition'][ $scope.settings.language.code]['remindermailhtml'] += ' ##' + fieldLink + '##'


        $scope.doSelectMailingLog = (log) ->
            $scope.selectedLog = log

            $scope.logOptionsDS = new kendo.data.DataSource({
                            data: new kendo.data.ObservableArray($scope.selectedLog.report.data)
                            schema: schema
                            pageSize: 500
            })

            $scope.logOptions.columns = $scope.selectedLog.report.columns
            $scope.logOptions.dataSource.data $scope.logOptionsDS.data()

            $scope.SetSubchapter('logdetail')


        $scope.doExportPrototypeFile = (event) ->
            event.preventDefault()
            if $scope.validatorExport.validate()
                $scope.validationClass   = 'valid'
                $scope.jobsService.obj.$add {'type':'export_mailing_clientcontacts', 'state' : 'WAITING_FOR_PROCESS', 'data':{'email':$scope.exportemail, 'survey':$scope.settings.survey, 'user':$scope.clientId, 'mailing': $scope.settings.mailing, 'prototype' : true}}
                alert 'In couple of minutes the system will send you an email with the prototype file you need to use.'
                #$scope.SetChapter('prototype')
            else
                $scope.validationClass = 'invalid'
            return


        $scope.doExportRespondentsFile = (event) ->
            event.preventDefault()
            if $scope.validatorExport.validate()
                $scope.validationClass   = 'valid'
                $scope.jobsService.obj.$add {'type':'export_mailing_clientcontacts', 'state' : 'WAITING_FOR_PROCESS', 'data':{'email':$scope.exportemail, 'survey':$scope.settings.survey, 'user':$scope.clientId, 'mailing': $scope.settings.mailing, 'prototype' : false}}
                alert 'In couple of minutes the system will send you an email with the prototype file you need to use.'
                #$scope.SetChapter('respondents')
            else
                $scope.validationClass = 'invalid'
            return



        $scope.onImportRespondentsFileSelect = (event) ->
            $scope.uploadStatus = 'IN_PROCESS'
            $scope.$digest()
            $scope.$apply()


        $scope.onImportRespondentsFileSuccess = (event) ->
            $scope.uploadStatus = 'FINISHED'
            $scope.mailingsImportStatusService.ref.set {inprogress : true, status : false , message : 'Waiting for process'}
            $scope.SetSubchapter('status')
            $scope.$digest()
            $scope.$apply()


        $scope.onImportRespondentsFileError = (event) ->
            $scope.uploadStatus = 'FINISHED'
            $scope.$digest()
            $scope.$apply()


        $scope.onImportRespondentsFileExtra = (event) ->
            event.data =  {'survey':$scope.settings.survey, 'user':$scope.clientId, 'mailing': $scope.settings.mailing}


        $scope.doClearImportRespondentsFile = ->
            $(".k-upload-files").remove()
            $(".k-upload-status").remove()
            $(".k-upload.k-header").addClass("k-upload-empty")
            $(".k-upload-button").removeClass("k-state-focused")
            $scope.uploadStatus = null


        $scope.onUpdateRespondentsFileSelect = (event) ->
            $scope.uploadStatus = 'IN_PROCESS'
            $scope.$digest()
            $scope.$apply()


        $scope.onUpdateRespondentsFileSuccess = (event) ->
            $scope.uploadStatus = 'FINISHED'
            $scope.mailingsImportStatusService.ref.set {inprogress : true, status : false , message : 'Waiting for process'}
            $scope.SetSubchapter('status')
            $scope.$digest()
            $scope.$apply()


        $scope.onUpdateRespondentsFileError = (event) ->
            $scope.uploadStatus = 'FINISHED'
            $scope.$digest()
            $scope.$apply()


        $scope.onUpdateRespondentsFileExtra = (event) ->
            event.data =  {'survey':$scope.settings.survey, 'user':$scope.clientId, 'mailing': $scope.settings.mailing}

        $scope.doClearUpdateRespondentsFile = ->
            $(".k-upload-files").remove()
            $(".k-upload-status").remove()
            $(".k-upload.k-header").addClass("k-upload-empty")
            $(".k-upload-button").removeClass("k-state-focused")
            $scope.uploadStatus = null


        $scope.doSendMailing = ->
            if confirm("Are you sure you are ready to send the mailing?") == true
                $scope.selectedMailing.progress = 0
                $scope.selectedMailing.status   = 'MAILING_WAIITING'
                $scope.doSaveSelectedMailing()
                $scope.jobsService.obj.$add {'type':'send_mailing', 'state' : 'WAITING_FOR_PROCESS', 'data':{'survey':$scope.settings.survey, 'user':$scope.clientId, 'mailing': $scope.settings.mailing, 'type':'mailing'}}
                alert 'In couple of minutes the system will start sending the mailing to the recipients in the list.'
                $scope.SetSubchapter('sending')


        $scope.$watch "settings", ((newVal, oldVal) ->

            if newVal
                if newVal.chapter == 'respondents' and not $scope.loadingRespondents
                    $scope.DisplayRespondents()
        ), true

    ]





##############################################################################
## UploadsListController
##############################################################################
controllers.controller 'UploadsListController', [
    '$scope'
    '$location'
    '$cookieStore'
    'currentAuth'
    'UserRef'
    'ClientDocumentFileRef'
    'ClientDocumentFile'
    'Auth'
    ($scope, $location, $cookieStore, currentAuth, UserRef, ClientDocumentFileRef, ClientDocumentFile, Auth) ->
        $scope.survey             = $cookieStore.get('survey')
        $scope.userService        = UserRef.getUserService(currentAuth.uid)
        $scope.client             = null
        $scope.clientService      = null
        $scope.password           = b64_encode_string($cookieStore.get('password'))
        $scope.clientDocuments    = null
        $scope.folders            = [{id : 'agreement',     label : 'Data Processor Agreement'},
                                     {id : 'plan',          label : 'Project Plan'},
                                     {id : 'database',      label : 'Database format'},
                                     {id : 'communication', label : 'Communication'},
                                     {id : 'questionnaire', label : 'Questionnaire'},
                                     {id : 'report',        label : 'Report'},
                                    ]
        $scope.selectedfolder     = null


        $scope.userService.obj.$loaded().then ->
            authorizedUser = false
            if $scope.userService.obj.info.role in ['client','clientadmin']
                authorizedUser  = true
            if not authorizedUser
                $location.path '/notauthorized/'
            if $scope.userService.obj.info.role == 'clientadmin'
                $scope.client = $cookieStore.get('client')
                $scope.clientService = UserRef.getUserService($scope.client)
            if $scope.userService.obj.info.role == 'client'
                $scope.clientService = $scope.userService
                $scope.client = currentAuth.uid

            $scope.clientDocuments = ClientDocumentFileRef.getClientDocumentsService($scope.client,$scope.survey)



        $scope.doOpenFolder = (folder) ->
            $scope.selectedfolder = folder

        $scope.onUploadClientFileSelect = (event) ->
            $scope.uploadStatus = 'IN_PROCESS'
            $scope.$digest()
            $scope.$apply()


        $scope.onUploadClientFileSuccess = (event) ->
            $scope.uploadStatus = 'FINISHED'
            $scope.doClearUploadClientFile()
            $scope.$digest()
            $scope.$apply()


        $scope.onUploadClientFileError = (event) ->
            $scope.uploadStatus = 'FINISHED'
            $scope.doClearUploadClientFile()
            $scope.$digest()
            $scope.$apply()


        $scope.onUploadClientFileExtra = (event) ->
            event.data =  {'survey':$scope.survey, 'client':$scope.client, 'folder' : $scope.selectedfolder, 'owner':currentAuth.uid }

        $scope.doClearUploadClientFile = ->
            $(".k-upload-files").remove()
            $(".k-upload-status").remove()
            $(".k-upload.k-header").addClass("k-upload-empty")
            $(".k-upload-button").removeClass("k-state-focused")
            $scope.uploadStatus = null

        $scope.GetDate = (date) ->
            if date
                return toJSONLocal(new Date(date))
                #systime = new Date(Number(date))
                #return systime.toLocaleString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })


        $scope.doRemoveClientDocument = (document) ->
            if confirm("Are you sure you want to delete this document?") == true

                ClientDocumentFile.delete($scope.survey, currentAuth.uid, $scope.client, document).then(
                    (data) =>
                      console.log data
                    (error) ->
                        console.log "Get query data failed " + error
                )



]
