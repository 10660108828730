app = angular.module("clientApp", ["clientApp.filters", "clientApp.services", "clientApp.directives", "clientApp.controllers","ngRoute","ngCookies","ngSanitize", "kendo.directives", "firebase", "ngCkeditor"])

app.factory 'Auth', ['$firebaseAuth', ($firebaseAuth) ->
    $firebaseAuth()
]

app.config ["$locationProvider","$routeProvider", ($locationProvider, $routeProvider) ->
    $locationProvider.hashPrefix ''
    $routeProvider.when('/login',  {
        templateUrl: "static/partials/login.html"
        controller: "LoginController"
    })
    $routeProvider.when('/adminlogin',  {
        templateUrl: "static/partials/adminlogin.html"
        controller: "AdminLoginController"
        resolve: {"currentAuth": ["Auth", (Auth) -> Auth.$requireSignIn()]}
    })
    $routeProvider.when('/logout',  {
        templateUrl: "static/partials/logout.html"
        controller: "LogoutController"
    })
    $routeProvider.when('/notauthorized',  {
        templateUrl: "static/partials/notauthorized.html"
        controller: "NotAuthorizedController"
    })
    $routeProvider.when('/clients',  {
        templateUrl: "static/partials/clients.html"
        controller: "ClientsController"
        resolve: {"currentAuth": ["Auth", (Auth) -> Auth.$requireSignIn()]}
    })
    $routeProvider.when('/surveys',  {
        templateUrl: "static/partials/surveys.html"
        controller: "SurveysController"
        resolve: {"currentAuth": ["Auth", (Auth) -> Auth.$requireSignIn()]}
    })
    $routeProvider.when('/overview',  {
        templateUrl: "static/partials/overview.html"
        controller: "OverviewController"
        resolve: {"currentAuth": ["Auth", (Auth) -> Auth.$requireSignIn()]}
    })
    $routeProvider.when( "/mailings",  {
        templateUrl: "static/partials/mailings/mailings_list.html",
        controller: "MailingsListController"
        resolve: {"currentAuth": ["Auth", (Auth) -> Auth.$requireSignIn()]}
    })
    $routeProvider.when( "/mailing/:mailingId",  {
        templateUrl: "static/partials/mailings/mailings_item.html"
        controller: "MailingItemController"
        resolve: {"currentAuth": ["Auth", (Auth) -> Auth.$requireSignIn()]}
    })
    $routeProvider.when( "/uploads",  {
        templateUrl: "static/partials/uploads/uploads_list.html",
        controller: "UploadsListController"
        resolve: {"currentAuth": ["Auth", (Auth) -> Auth.$requireSignIn()]}
    })

    $routeProvider.otherwise({redirectTo: '/login'})
]



app.run ($rootScope, $location) ->
    $rootScope.$on '$routeChangeStart', (event, next, current) ->
        return
    $rootScope.$on '$routeChangeError', (event, next, previous, error) ->
        if error == "AUTH_REQUIRED"
            $location.url "/login"
        return

