replaceAll = (s, m, r, p) ->
    if s == p or r.indexOf(m) > -1 then s else replaceAll(s.replace(m, r), m, r, s)



if not window.btoa?
    base64_encode = window.base64.encode
else
    base64_encode = window.btoa

if not window.atob?
    base64_decode = window.base64.decode
else
    base64_decode = window.atob


b64safe = "data:b64:safe,"


b64_encode_safe = (s) ->
    if not exist(s)
        return s

    if not exist(s.indexOf)
        return s

    if s.indexOf(b64safe) == 0
        return s

    s = encodeURIComponent(s)
    s = base64_encode(s)
    s = s.replace /\=/g, "-"
    return b64safe + s


b64_decode_safe = (s) ->
    if not exist(s)
        return s

    if not exist(s.indexOf)
        return s

    if s.indexOf(b64safe) != 0
        return s

    s = s.replace(b64safe, "")
    s = s.replace /-/g, "="
    s = base64_decode(s)
    try
        s = decodeURIComponent(s)
    catch error
        s = "error decoding"

    return s


object_b64_safe = (v) ->
    set_var = (k) ->
        v[k] = object_b64_safe(v[k])

    if _.isObject(v)
        _.each(_.keys(v), set_var)
        return v
    else
        v = b64_encode_safe(v)
        return v


b64_object_safe = (v) ->
    set_var = (k) ->
        v[k] = b64_object_safe(v[k])

    if _.isObject(v)
        _.each(_.keys(v), set_var)
        return v
    else
        v = b64_decode_safe(v)
        return v

exist_string = (value) ->
    if not value?
        return false
    else
        switch value
            when undefined, null, "null", "undefined"
                return false
            else
                return true

exist = (value) ->
    if value == null
        return false
    else if typeof(value) == "undefined"
        return false
    else if _.isArray(value)
        return not _.isEmpty(value)
    else if _.isObject(value)
        if _.size(value) == 0
            if _.isFunction(value)
                return true
            else if _.isBoolean(value)
                return true
            else if _.isElement(value)
                return true
            else if _.isDate(value)
                return true
            else if value == localStorage
                return true

            return not _.isEmpty(value)

    if exist_string(value)
        if value == ""
            return false

        if String(value) == "NaN"
            return false

        if String(value) == "undefined"
            return false

        if value.trim?
            if value.trim() == ""
                return false

        return true
    else
        return false


    Object::clone = ->
        newObj = if this instanceof Array then [] else {}
        for i of this
            `i = i`
            if i == 'clone'
                continue
            if @[i] and typeof @[i] == 'object'
                newObj[i] = @[i].clone()
            else
                newObj[i] = @[i]
        newObj

